$_blue: #5f909c;
$footer-height:130px;

* {
    box-sizing: border-box;
}

.logo {
    max-height:50px;
}

.header {
    background: #ddd;
}

.bd-nav {
    background: #356b2d;
    border-top:2px solid #9ddbab;

    ul {
        list-style-type: none;
        padding-left:0;
        margin-bottom:0;
        width:100%;

        li {
            display: inline-block;
            padding:0;
            margin:0;
            a {
                display:inline-block;
                text-decoration: none;
                color:#fff;
                padding:10px;
                transition: background 0.6s;
            }
        }
    }
}
@media only screen and (min-width: 576px) {
    .bd-nav {
        li {
            a:hover {
                background:$_blue;
            }
        }
    }
}

@media only screen and (min-width: 576px) {
    .content {
        padding-left:0;
        padding-right:0;
    }
}


h2 {
    color:#547991;
    font-family: 'Cinzel', serif;
}
h3 {
    color: $_blue;
}

p {
    margin-bottom:1.5em;
}

.btn-primary {
    background:#497c8a;
    border-color:#497c8a;
    &:hover, &:active {
        background:#497c8a;
        border-color:#497c8a;
    }
    width:100%;
}
@media only screen and (min-width: 576px) {
    .btn-primary {
        width:auto;
    }
}

.bd-footer {
    background:#eee;
    .col {
        min-height:$footer-height;
    }
}

.map {
    width:100%;
}
@media only screen and (min-width: 576px) {
    .map {
        max-width:25%;
    }
}
